import React from "react"
import SinglePost from "../components/singlepost"

export default ({ posts, post_type, limit }) => {
  const postSupported = whichPostsToDisplay(post_type)
  return (
    <>
      {posts
        .filter(({ node }) => postSupported.includes(node.frontmatter.post_type))
        .slice(0, limit ? limit : posts.length)
        .map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const slug = node.fields.slug
          const date = node.frontmatter.date
          const description = node.frontmatter.description
          const excerpt = node.excerpt
          return (
            <SinglePost
              key={slug}
              slug={slug}
              title={title}
              date={date}
              description={description}
              excerpt={excerpt}
            />
          )
        })}
    </>
  )
}


function whichPostsToDisplay(post_type) {
  if(post_type == "both") {
    return ["note", "blog"]
  } else {
    return [post_type]
  }
}

