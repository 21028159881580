import React from "react"
import { Link, graphql } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionHeading from "../components/sectionheading"
import Posts from "../components/posts"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const themeColor = data.site.siteMetadata.themeColor
    const posts = data.allMarkdownRemark.edges
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" />
        <SectionHeading title={"All Posts"} />
        <Posts posts={posts} post_type="both" limit={500} />
      </Layout>
    )
  }
}

export default BlogIndex

export const NextPage = ({ link, placeholder, themeColor }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      paddingBottom: "10px",
    }}
  >
    <Link
      to={`/${link}`}
      style={{
        color: themeColor,
        display: "flex",
        alignItems: "center",
      }}
    >
      <p
        style={{
          margin: 0,
        }}
      >
        {placeholder}
      </p>
      <span
        style={{
          paddingLeft: "10px",
        }}
      >
        <FaArrowRight />
      </span>
    </Link>
  </div>
)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        themeColor
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            post_type
          }
        }
      }
    }
  }
`
