import React from "react"
import { rhythm } from "../utils/typography"
import { Link, useStaticQuery, graphql } from "gatsby"

function SinglePost({ slug, title, date, description, excerpt }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          themeColor
        }
      }
    }
  `)
  const themeColor = data.site.siteMetadata.themeColor
  return (
    <article>
      <header>
        <p
          style={{
            marginBottom: rhythm(1 / 10),
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <small style={{
            marginRight: '1.5rem',
            minWidth: '120px'
          }}><i>{date}</i></small>

          <Link style={{ boxShadow: `none`, 
            color: themeColor, fontWeight: "bolder", fontSize: '1.2rem', marginRight: '10px' }} to={slug}>
            {title}
          </Link>
        </p>
      </header>
    </article>
  )
}

export default SinglePost
